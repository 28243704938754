import { Auth0Plugin } from "@auth0/auth0-vue";
import { ErrorType } from "../models/metadata/error.ts";
import Resources from "../models/resources.ts";
import { ContactFormAreasResponse } from "../models/responses/contactFormAreasResponse.ts";
import { BackendServiceBase } from "./backendServiceBase.ts";
import { BlockNavigationService } from "./blockNavigationService.ts";
import { ErrorsService } from "./errorsService.ts";

export class ContactFormAreasService extends BackendServiceBase<any, ContactFormAreasResponse> {
  
  constructor(
    private readonly resources: Resources,
    blockNavigationService: BlockNavigationService,
    errorsService: ErrorsService,
    auth0Plugin: Auth0Plugin,
    backendEndpoint: string
  ) {
    super(blockNavigationService, errorsService, auth0Plugin, backendEndpoint, ErrorType.ContactFormAreas);
  }

  protected override defineErrorMessage(): string {
    return this.resources.errors.contactFormAreas;
  }

  protected buildFetch(headers: any): Promise<Response> {
    return fetch(`${this.backendEndpoint}/GetContactFormAreas`, {
      headers: headers,
      method: 'GET',
    });
  }
}
