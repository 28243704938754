import { isNumericLiteral } from "typescript";
import Resources from "../models/resources.ts";
import { StringHelper } from "./stringHelper.ts";

export class ValidationRulesService {
  // ASCII extended range printable characters
  private readonly lettersRegex: RegExp = /^[\x20-\x7E\xA0-\xFFŠŒŽšŸžœ]*$/u;
  private readonly pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  private readonly resources!: Resources;
  private readonly stringHelper!: StringHelper;
  private readonly hasNumber: RegExp = /\d+/g;
  private validationRules: any;
  constructor(resources: Resources, stringHelper: StringHelper) {
    this.resources = resources;
    this.stringHelper = stringHelper;
  }
  private commonComposedNameValidation = (value: string, nameType: string) => this.commonNameValidation(value, nameType, 301, 2);
  private commonFirstAndLastNameValidation = (value: string, nameType: string) => this.commonNameValidation(value, nameType, 150, 2);
  private commonNameValidation(value: string, nameType: string, max: number, min: number): string | true {
    if (!value) return this.stringHelper.formatValues(this.resources.messages.validationPleaseEnter, [nameType]);

    if (value.length > max) return this.stringHelper.formatValues(this.resources.messages.validationNumbCharacters, [this.resources.messages.max, max]);

    if (!this.lettersRegex.test(value)) return this.resources.messages.validationOnlyLetters;

    return true; //for the library to work
  }
  private dropdownValidation(value: string): string | true {
    return value !== '' || this.resources.messages.selectDropdownRequired;
  }
  private textAreaValidation(value: string): string | true {
    return value && value.length <= 200 && value.length > 0 ? true : this.resources.messages.validationTextArea;
  }

  public getValidationRules(): any {
    if (this.validationRules) return this.validationRules;
    this.validationRules = {
      otpCode: [
        (value: string) => value.split("").filter((char) => isNaN(parseInt(char))).length === 0 || this.resources.messages.onlyNumbers,
        (value: string) => value.length === 6 || this.stringHelper.formatValues(this.resources.messages.fixedNumbers, [6]),
      ],
      composedName: [(value: string) => this.commonComposedNameValidation(value, this.resources.messages.name)],
      firstName: [(value: string) => this.commonFirstAndLastNameValidation(value, this.resources.messages.firstName)],
      lastName: [(value: string) => this.commonFirstAndLastNameValidation(value, this.resources.messages.lastName)],
      email: [
        this.emailValidationRules.emailFormatRequired,
        // this.emailValidationRules.email,
      ],
      emailToBeContacted: [this.emailValidationRules.emailToGetContacted],
      password: [
        (v: string) => {
          return v.length >= 8 || this.stringHelper.formatValues(this.resources.messages.validationNumbCharacters, [this.resources.messages.min, 8]);
        },
        (v: string) => {
          const validations = [/\d/.test(v), /[a-z]/.test(v), /[A-Z]/.test(v), /[!¡"@#$%^&*)(+=._-]/.test(v)];

          return validations.filter((result) => result).length >= 3 || this.resources.messages.passwordStrengthNumber;
        },
      ],
      repeatPassword: [(value: string) => !!value || this.resources.messages.passwordEqualityError],
      dropdown: [(value: string) => this.dropdownValidation(value)],
      textarea: [(value: string) => this.textAreaValidation(value)],
    };
    return this.validationRules;
  }

  private emailValidationRules = {
    emailFormatRequired: (value: string) => {
      if (!value) {
        return this.resources.messages.validationEmail;
      } else if (value.length > 254) {
        return this.stringHelper.formatValues(this.resources.messages.validationNumbCharacters, [this.resources.messages.max, 254]);
      } else if (!this.pattern.test(value)) {
        return this.stringHelper.formatValues(this.resources.messages.validationEmail, [this.resources.messages.email]);
      }
      return true;
    },
    emailToGetContacted: (value: string) => {
      if (!value) {
        return this.resources.messages.validationEmailContact;
      }
    },
  };
}
