export interface Error {
    message: string,
    id: string,
    type: ErrorType
}

export enum ErrorType {
    ContactForm = 'contactForm',
    ReCaptcha = 'reCaptcha',
    UpdateName = 'updateName',
    UpdateField = 'updateField',
    ResendEmailVerification = 'resendEmailVerification',
    Silent = 'silent',
    OtpCode = "otpCode",
    StopOtpCode = "stopOtpCode",
    OtpLogin = "OtpLogin",
    General = "General",
    EmailAlreadyTaken = "EmailAlreadyTaken",
    ContactFormAreas = "ontactFormAreas"
}