import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, withModifiers as _withModifiers, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c179bfec"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "aside aside-right" }
const _hoisted_2 = { class: "componentTitle" }
const _hoisted_3 = { class: "cardMainContent" }
const _hoisted_4 = { class: "componentText" }
const _hoisted_5 = { class: "label" }
const _hoisted_6 = { class: "label" }
const _hoisted_7 = { class: "label" }
const _hoisted_8 = { class: "label" }
const _hoisted_9 = { class: "checkbox-wrapper" }
const _hoisted_10 = { class: "buttons-wrapper" }
const _hoisted_11 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LeftPanel = _resolveComponent("LeftPanel")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_ErrorsManager = _resolveComponent("ErrorsManager")!
  const _component_BlockableButton = _resolveComponent("BlockableButton")!
  const _component_RadialSpinner = _resolveComponent("RadialSpinner")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'flex-center': !_ctx.isAuthenticatedForLeftPanel }, "wrapper contact-form-wrapper"])
  }, [
    (_ctx.isAuthenticatedForLeftPanel)
      ? (_openBlock(), _createBlock(_component_LeftPanel, {
          key: 0,
          showContactFormLink: false,
          buttonInfo: _ctx.buttonInfo,
          buttonInfoLoading: _ctx.buttonInfoLoading,
          isAuthenticated: _ctx.isAuthenticatedForLeftPanel
        }, null, 8, ["buttonInfo", "buttonInfoLoading", "isAuthenticated"]))
      : _createCommentVNode("", true),
    _createElementVNode("aside", _hoisted_1, [
      _createVNode(_component_v_form, {
        "validate-on": "submit",
        onSubmit: _cache[8] || (_cache[8] = _withModifiers(() => {}, ["prevent"])),
        modelValue: _ctx.isValid,
        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.isValid) = $event))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.contactCS), 1),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.contactFormDescription), 1),
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.labels.name), 1),
            _createVNode(_component_v_text_field, _mergeProps({ class: "input" }, _ctx.vProps, {
              modelValue: _ctx.contactFormData.lastUserName,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.contactFormData.lastUserName) = $event)),
              rules: _ctx.rulesFirstName,
              disabled: _ctx.isAuthenticated,
              "validate-on": "blur"
            }), null, 16, ["modelValue", "rules", "disabled"]),
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.labels.email), 1),
            _createVNode(_component_v_text_field, _mergeProps({ class: "input" }, _ctx.vProps, {
              modelValue: _ctx.contactFormData.lastUserEmail,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.contactFormData.lastUserEmail) = $event)),
              rules: _ctx.rulesEmail,
              disabled: _ctx.isAuthenticated,
              "validate-on": "blur"
            }), null, 16, ["modelValue", "rules", "disabled"]),
            _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.labels.form), 1),
            _createVNode(_component_v_select, _mergeProps({ rules: _ctx.rulesDropdown }, _ctx.vProps, {
              modelValue: _ctx.contactFormData.contactFormArea,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.contactFormData.contactFormArea) = $event)),
              "item-text": "title",
              "item-value": "index",
              items: _ctx.contactFormAreas,
              "validate-on": "blur"
            }), null, 16, ["rules", "modelValue", "items"]),
            _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.labels.textArea), 1),
            _createVNode(_component_v_textarea, _mergeProps({
              rules: _ctx.rulesTextArea,
              "validate-on": "input"
            }, _ctx.vProps, {
              modelValue: _ctx.contactFormData.details,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.contactFormData.details) = $event)),
              counter: 200,
              onInput: _ctx.onTextAreaInput,
              class: "text-area"
            }), null, 16, ["rules", "modelValue", "onInput"]),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_9, [
                (!_ctx.isAuthenticated)
                  ? (_openBlock(), _createBlock(_component_Checkbox, {
                      key: 0,
                      modelValue: _ctx.reCaptchaResponse,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.reCaptchaResponse) = $event)),
                      onSuccess: _cache[5] || (_cache[5] = ($event: any) => (_ctx.reCaptchaSuccess())),
                      onExpired: _cache[6] || (_cache[6] = ($event: any) => (_ctx.reCaptchaExpired())),
                      class: "reCaptcha"
                    }, null, 8, ["modelValue"]))
                  : _createCommentVNode("", true)
              ]),
              _createVNode(_component_ErrorsManager, { errorTypes: _ctx.errorTypes }, null, 8, ["errorTypes"]),
              _createElementVNode("div", _hoisted_10, [
                (_ctx.isAuthenticated)
                  ? (_openBlock(), _createBlock(_component_BlockableButton, {
                      key: 0,
                      route: "/userProfile",
                      buttonText: _ctx.buttonBack,
                      underlined: ""
                    }, null, 8, ["buttonText"]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", null, [
                  (_ctx.loading)
                    ? (_openBlock(), _createBlock(_component_RadialSpinner, { key: 0 }))
                    : (_openBlock(), _createElementBlock("button", {
                        key: 1,
                        type: "submit",
                        onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args))),
                        class: _normalizeClass(["send-btn", { 'disabled-button': _ctx.isButtonDisabled }]),
                        disabled: _ctx.isButtonDisabled
                      }, _toDisplayString(_ctx.buttonSend), 11, _hoisted_11))
                ])
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ])
  ], 2))
}