
import { Services } from "@/core/models/metadata/services.ts";
import {
  ContactFormQuery,
  PublicContactFormQuery,
} from "@/core/models/queries/contactFormQuery.ts";
import Resources from "@/core/models/resources.ts";
import { PublicContactFormService } from "@/core/services/publicContactFormService.ts";
import { PrivateContactFormService } from "@/core/services/privateContactFormService.ts";
import { useAuth0 } from "@auth0/auth0-vue";
import { inject } from "vue";
import { defineComponent } from "vue";
import { ValidationRulesService } from "@/core/services/validationRulesService.ts";
import { RemoteOriginatingSiteResponse } from "@/core/models/responses/remoteOriginatingSiteResponse.ts";
import RemoteOriginatingSiteService from "@/core/services/remoteOriginatingSiteService.ts";
import RadialSpinner from "../shared/radialSpinner.vue";
import BlockableButton from "../shared/blockableButton.vue";
import { Checkbox } from "vue-recaptcha/head";
import { ContactFormResponse } from "@/core/models/responses/contactFormResponse.ts";
import LeftPanel from "../userProfile/leftPanel.vue";
import ErrorsManager from "../shared/errorsManager.vue";
import { ErrorType } from "@/core/models/metadata/error.ts";
import { ErrorsService } from "@/core/services/errorsService.ts";
import { ContactFormAreasService } from "@/core/services/contactFormAreasService.ts";
import { ContactFormAreasResponse } from "@/core/models/responses/contactFormAreasResponse.ts";

let resources!: Resources;
let publicContactFormService!: PublicContactFormService;
let privateContactFormService!: PrivateContactFormService;
let validationRulesService!: ValidationRulesService;
let remoteOriginatingSiteService!: RemoteOriginatingSiteService;
let contactFormAreasService!: ContactFormAreasService;
let errorsService!: ErrorsService;
let buttonInfo: RemoteOriginatingSiteResponse = {
  isSuccess: false,
  FailureMessage: "",
  title: "",
  url: "",
};
interface ContactFormData {
  lastUserName: string;
  lastUserEmail: string | undefined;
  contactFormArea:  number | string;
  details: string;
}
interface ContactFormArea {
  index: number;
  title: string;
}

export default defineComponent({
  name: "ContactForm",
  mounted() {
    remoteOriginatingSiteService = inject(
      Services.RemoteOriginatingSiteService
    ) as RemoteOriginatingSiteService;
    remoteOriginatingSiteService
      .retrieveUrlAndTitleFromNotDefault()
      .then((response: RemoteOriginatingSiteResponse) => {
        this.buttonInfo = response;
        this.buttonInfoLoading = false;
      });
    contactFormAreasService = inject(Services.ContactFormAreas) as ContactFormAreasService;
    contactFormAreasService.handle()
    .then((response: ContactFormAreasResponse) => {
      this.contactFormAreas = response.response.map((item) => ({
        index: item.index,
        title: item.name 
      }));
    });
    // Added Code: Clear local storage on refresh
    window.addEventListener('beforeunload', this.clearLocalStorage);
    // Added Code: Retrieve stored data when component mounts
    const storedData = JSON.parse(localStorage.getItem('contactFormData') || '{}');
    if (storedData) {
      this.contactFormData = { ...this.contactFormData, ...storedData }; // Merge with existing data
    }
  },
  data() {
    const auth0 = useAuth0();
    resources = inject(Services.Resources) as Resources;
    publicContactFormService = inject(
      Services.PublicContactForm
    ) as PublicContactFormService;
    privateContactFormService = inject(
      Services.PrivateContactForm
    ) as PrivateContactFormService;
    const isAuthenticated = auth0.isAuthenticated.value;
    validationRulesService = inject(
      Services.ValidationRules
    ) as ValidationRulesService;
    errorsService = inject(Services.ErrorsService)!;
    return {
      buttonBack: resources.buttons.back,
      canEditFields: !isAuthenticated,
      // Define contact form data as an object with correct types
      contactFormData: {
        lastUserName: isAuthenticated ? `${auth0.user.value.given_name} ${auth0.user.value.family_name}` : "",
        lastUserEmail: isAuthenticated ? auth0.user.value.email : undefined,
        contactFormArea: resources.messages.defaultAreaOptionDropdown,
        details: ""
      } as ContactFormData, // Cast to ContactFormData type
      buttonSend: resources.buttons.send,
      loading: false,
      contactCS: resources.messages.contactCS,
      
      contactFormAreas: [] as ContactFormArea[],
      contactFormDescription: resources.messages.contactFormDescription,
      userId: isAuthenticated ? auth0.user.value.sub : "",
      isValid: false,
      isAuthenticated: isAuthenticated,
      isAuthenticatedForLeftPanel: isAuthenticated && (auth0.user.value?.email_verified ?? false),
      defaultOptionDropdown: resources.messages.defaultOptionDropdown,
      rulesFirstName: isAuthenticated
        ? []
        : validationRulesService.getValidationRules().composedName,
      rulesEmail: isAuthenticated
        ? []
        : validationRulesService.getValidationRules().email,
      rulesDropdown: validationRulesService.getValidationRules().dropdown,
      rulesTextArea: validationRulesService.getValidationRules().textarea,
      message: resources.titles.message,
      buttonInfo,
      buttonInfoLoading: true,
      reCaptchaResponse: "",
      reCaptchaIsSuccessful: false,
      labels: {
        name: resources.labels.yourName,
        email: resources.labels.yourEmail,
        form: resources.labels.formDropdown,
        textArea: resources.labels.textArea,
      },
      errorTypes: [ErrorType.ContactForm, ErrorType.ReCaptcha],
      charCount: 0,
    };
  },
  watch: {
    contactFormData: {
      handler(newValue) {
        localStorage.setItem('contactFormData', JSON.stringify(newValue)); // Save to local storage
      },
      deep: true, // Watch for deep changes in the object   
    },
  },
  methods: {
    async submitForm() {
      if (!this.isValid) return;
      errorsService.deleteByType(ErrorType.ReCaptcha);
      if (!this.reCaptchaIsSuccessful && !this.isAuthenticated) {
        errorsService.push(resources.errors.verifyRecaptcha, ErrorType.ReCaptcha)
        return;
      }
      this.loading = true;
      let response: ContactFormResponse;
      if (this.isAuthenticated) {
        const contactFormQuery = {
          name: this.contactFormData.lastUserName,
          email: this.contactFormData.lastUserEmail,
          area: this.contactFormData.contactFormArea,
          details: this.contactFormData.details,
          referrer: this.buttonInfo.url,
        } as ContactFormQuery;
        response = await privateContactFormService.handle(contactFormQuery);
      } else {
        const contactFormQuery = {
          name: this.contactFormData.lastUserName,
          email: this.contactFormData.lastUserEmail,
          area: this.contactFormData.contactFormArea,
          details: this.contactFormData.details,
          reCaptchaResponseToken: this.reCaptchaResponse,
          referrer: this.buttonInfo.url,
        } as PublicContactFormQuery;
        response = await publicContactFormService.handle(contactFormQuery);
      }

      this.loading = false;
      this.reCaptchaIsSuccessful = false;

      if (response.isSuccess) {
        localStorage.removeItem('contactFormData');
        this.$router.push("/contactForm/contactFormSent");
        // Resetting all fields
        this.contactFormData.lastUserName = "";
        this.contactFormData.lastUserEmail = undefined;
        this.contactFormData.details = "";
        this.contactFormData.contactFormArea = resources.messages.defaultAreaOptionDropdown; // Reset dropdown to default
      }
    },
    reCaptchaSuccess() {
      this.reCaptchaIsSuccessful = true;
      errorsService.deleteByType(ErrorType.ReCaptcha);
    },
    reCaptchaExpired() {
      this.reCaptchaIsSuccessful = false;
    },
    clearLocalStorage() {
      localStorage.removeItem('contactFormData'); // Clear local storage on refresh 
    },
    onTextAreaInput() {
      const value = this.contactFormData.details;
      this.charCount = value.length;
      if (value.length == 0){
        this.rulesTextArea = [resources.messages.validationTextArea];
      }
      else if (value.length > 0 && value.length <= 200) {
        this.rulesTextArea = [];
      } 
      else if (value.length > 200) {
        this.rulesTextArea = [resources.messages.warningTextArea];
      } 
    },   
  },
  components: {
    ErrorsManager,
    RadialSpinner,
    Checkbox,
    LeftPanel,
    BlockableButton,
  },
  computed: {
    isButtonDisabled() {
    return this.isAuthenticated
      ? !this.isValid 
      : !(this.isValid && this.reCaptchaIsSuccessful); 
  },
    vProps() {
      return {
        variant: "outlined",
        density: "compact",
      };
    },
  },
});
