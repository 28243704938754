import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "aside aside-left" }
const _hoisted_3 = {
  class: "footer-links",
  href: "https://www.rsc.org/help-legal",
  target: "_blank"
}
const _hoisted_4 = {
  class: "footer-links",
  href: "https://www.rsc.org/help-legal/legal/privacy",
  target: "_blank"
}
const _hoisted_5 = {
  class: "footer-links",
  href: "https://www.rsc.org/help-legal/legal/accessibility",
  target: "_blank"
}
const _hoisted_6 = { class: "aside aside-right" }
const _hoisted_7 = { class: "footer-text" }
const _hoisted_8 = { class: "footer-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OneTrustCookie = _resolveComponent("OneTrustCookie")!

  return (_openBlock(), _createElementBlock("footer", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("aside", _hoisted_2, [
        _createElementVNode("a", _hoisted_3, _toDisplayString(_ctx.legal), 1),
        _createElementVNode("a", _hoisted_4, _toDisplayString(_ctx.privacy), 1),
        _createElementVNode("a", _hoisted_5, _toDisplayString(_ctx.accesibility), 1)
      ]),
      _createElementVNode("aside", _hoisted_6, [
        _createElementVNode("span", _hoisted_7, "© Royal Society of Chemistry " + _toDisplayString(new Date().getFullYear()), 1),
        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.copyrightRegistered), 1)
      ])
    ]),
    _createVNode(_component_OneTrustCookie)
  ]))
}